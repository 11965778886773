// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-100-days-challenge-123456789-js": () => import("./../src/pages/100-days-challenge-123456789.js" /* webpackChunkName: "component---src-pages-100-days-challenge-123456789-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-seeking-draft-js": () => import("./../src/pages/community-seeking-draft.js" /* webpackChunkName: "component---src-pages-community-seeking-draft-js" */),
  "component---src-pages-community-seeking-js": () => import("./../src/pages/Community-Seeking.js" /* webpackChunkName: "component---src-pages-community-seeking-js" */),
  "component---src-pages-crikens-colosseum-js": () => import("./../src/pages/Crikens-Colosseum.js" /* webpackChunkName: "component---src-pages-crikens-colosseum-js" */),
  "component---src-pages-english-cards-123456789-2-js": () => import("./../src/pages/english-cards-123456789-2.js" /* webpackChunkName: "component---src-pages-english-cards-123456789-2-js" */),
  "component---src-pages-english-cards-123456789-js": () => import("./../src/pages/english-cards-123456789.js" /* webpackChunkName: "component---src-pages-english-cards-123456789-js" */),
  "component---src-pages-english-cards-123456789-y-js": () => import("./../src/pages/english-cards-123456789-y.js" /* webpackChunkName: "component---src-pages-english-cards-123456789-y-js" */),
  "component---src-pages-english-over-russian-js": () => import("./../src/pages/English-Over-Russian.js" /* webpackChunkName: "component---src-pages-english-over-russian-js" */),
  "component---src-pages-frame-ninja-js": () => import("./../src/pages/FrameNinja.js" /* webpackChunkName: "component---src-pages-frame-ninja-js" */),
  "component---src-pages-h-4242-fewix-3-js": () => import("./../src/pages/h4242fewix3.js" /* webpackChunkName: "component---src-pages-h-4242-fewix-3-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mbn-js": () => import("./../src/pages/MBN.js" /* webpackChunkName: "component---src-pages-mbn-js" */),
  "component---src-pages-obvious-aint-so-obvious-js": () => import("./../src/pages/Obvious-Aint-So-Obvious.js" /* webpackChunkName: "component---src-pages-obvious-aint-so-obvious-js" */),
  "component---src-pages-racism-sexism-lgbt-js": () => import("./../src/pages/Racism-Sexism-LGBT.js" /* webpackChunkName: "component---src-pages-racism-sexism-lgbt-js" */)
}

